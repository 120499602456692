import { MutableRefObject, useEffect, useRef } from "react";

export function useIsMounted(): MutableRefObject<boolean> {
  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );
  return componentIsMounted;
}
