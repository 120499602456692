import { ContactMethod } from '../types';
import { API } from 'aws-amplify';
import { AppointmentInfoFromApi, IKeyVal } from '../interfaces';

const BASE_URL = '/api';
const API_NAME = 'LagunaRestApi';

const buildApiUrl = (url: string, inputs?: Array<string>, params?: IKeyVal) => {
  let base = `${BASE_URL}/${url}`;
  if (inputs) base += `/${inputs.join('/')}`;
  if (params)
    base +=
      '?' +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
  return base;
};

interface scheduleAppointmentInput {
  utcDate: Date;
  appointmentMethod: ContactMethod;
  customerId: string;
  coachId: string;
  appointmentId: string;
}

const ApiRequests = {
  getAppointmentInfo: async (customerId: string) => {
    const response = await API.get(
      API_NAME,
      buildApiUrl('appointments', [customerId]),
      {}
    );
    return AppointmentInfoFromApi(response);
  },
  scheduleAppointment: (appointment: scheduleAppointmentInput) => {
    return API.post(
      API_NAME,
      buildApiUrl(`customers/${appointment.customerId}/appointment`),
      { body: { ...appointment } }
    );
  },
  register: (data: any, isFullForm?: boolean) => {
    return API.post(
      API_NAME,
      buildApiUrl(`customers/signup${isFullForm ? '/mayo' : ''}`),
      {
        body: { ...data },
      }
    );
  },
};

export default ApiRequests;
