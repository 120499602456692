const buildDate = (input: Date, t: any) => {
  const local = t('transLocal');
  const dayOfMonthStr = input.getDate();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const day = input.toLocaleString(local, { weekday: 'long' });
  let dayStr = day;
  if (input.toDateString() === today.toDateString()) dayStr = t('today');
  else if (input.toDateString() === tomorrow.toDateString())
    dayStr = t('tomorrow');

  const shortMonth = input.toLocaleString(local, { month: 'short' });
  const month = input.toLocaleString(local, { month: 'long' });
  return { day: dayStr, shortMonth, month, dayOfMonthStr };
};

const buildDateString = (input: Date | undefined, t: any) => {
  if (!input) return '';
  const { day, month, dayOfMonthStr } = buildDate(input, t);
  return `${day}, ${month} ${dayOfMonthStr}`;
};

const buildRangeSlotString = (
  input: Date | undefined,
  durationInMinutes: number
) => {
  if (!input) return '';
  const startTime = buildSlotString(input);
  const endDate = new Date(input.getTime() + durationInMinutes * 60000);
  const endTime = buildSlotString(endDate);
  return `${startTime} - ${endTime}`;
};

const buildSlotString = (input: Date | undefined) => {
  if (!input) return '';
  return input.toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });
};
const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// formats date of birth as expected by the server yyyy/mm/dd
const formatDateOfBirth = (dateOfBirth: string) => {
  return new Date(dateOfBirth).toISOString().split('T')[0].replace(/-/g, '/');
};

export {
  buildDateString,
  buildSlotString,
  buildRangeSlotString,
  buildDate,
  validateEmail,
  formatDateOfBirth,
};
