import {
  IonButton,
  IonContent,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToast,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ApiRequests from '../common/api/api';
import { formatDateOfBirth, validateEmail } from '../common/general';
import logo from '../resources/logo_laguna.png';
import Loader from './Loader';
import IMask from 'imask';

import './Register.css';
import LanguageSelector from '../common/components/LanguageSelector';
import useQueryParams from '../common/hooks/useQueryParams';
import { FormType } from '../common/types';

const Register: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [sex, setSex] = useState('male');
  const [language, setLanguage] = useState('en');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const phoneRef = useRef<any>();
  const zipRef = useRef<any>();
  const isFullForm = useQueryParams('form') === FormType.Full;

  const validateInputs = () => {
    const errors: any = {};

    if (!firstName) {
      const key = 'firstName';
      errors[key] = t('missingField', { key: t(key) });
    }
    if (!lastName) {
      const key = 'lastName';
      errors[key] = t('missingField', { key: t(key) });
    }
    if (email && !validateEmail(email)) {
      const key = 'email';
      errors[key] = t('invalidField', { key: t(key) });
    }
    if (!phone) {
      const key = 'phone';
      errors[key] = t('missingField', { key: t(key) });
    } else if (phoneRef?.current?.value?.length !== 17) {
      const key = 'phone';
      errors[key] = t('invalidField', { key: t(key) });
    }
    if (!zipCode) {
      const key = 'zipCode';
      errors[key] = t('missingField', { key: t(key) });
    } else if (zipRef?.current?.value?.length !== 5) {
      const key = 'zip';
      errors[key] = t('invalidField', { key: t(key) });
    }
    if (isFullForm && !dateOfBirth) {
      const key = 'dateOfBirth';
      errors[key] = t('missingField', { key: t(key) });
    }
    return errors;
  };

  const onSuccess = () => {
    history.push('success');
  };

  const submit = async () => {
    if (isSubmitting) return;
    let json: { [key: string]: string } = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone: phone,
      email: email.trim(),
      sex,
      language,
      zipCode,
    };
    if (isFullForm) {
      json = { ...json, dob2: formatDateOfBirth(dateOfBirth) };
    }
    const errors = validateInputs();
    const values = Object.values(errors);
    if (values.length > 0) {
      console.log(errors);
      setToastMessage(values.join(', '));
      return;
    }
    try {
      setIsSubmitting(true);
      await ApiRequests.register(json, isFullForm);
      onSuccess();
    } catch (e) {
      console.log('fail register user', e);
      if (e?.request?.status === 409) {
        //phone exists
        setToastMessage(t('phoneExists'));
      } else {
        setToastMessage(t('registerUserError'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const showToast = (message: string) => {
    return (
      <IonToast
        isOpen
        color='danger'
        position='top'
        onDidDismiss={() => setToastMessage('')}
        message={message}
        duration={6000}
      />
    );
  };

  return (
    <IonContent className='ion-padding register-form'>
      <Loader isLoading={isSubmitting} />
      {toastMessage && showToast(toastMessage)}
      <IonList className='list-register-form'>
        <LanguageSelector />
        <div className='form-title-div'>
          <img src={logo} className='logo' alt='logo' />
          <div className='form-title'>{t('registerTitle')}</div>
        </div>
        <IonRow>
          <IonItem style={{ width: '50%' }}>
            <IonLabel className='form-label' position='floating'>
              {t('firstName')}
            </IonLabel>
            <IonInput
              tabIndex={0}
              autocomplete='off'
              autocapitalize='on'
              required
              value={firstName}
              onIonChange={({ target: { value } }: any) =>
                setFirstName(value)
              }></IonInput>
          </IonItem>
          <IonItem style={{ width: '50%' }}>
            <IonLabel className='form-label' position='floating'>
              {t('lastName')}
            </IonLabel>
            <IonInput
              tabIndex={1}
              autocomplete='off'
              autocapitalize='on'
              required
              value={lastName}
              onIonChange={({ target: { value } }: any) =>
                setLastName(value)
              }></IonInput>
          </IonItem>
        </IonRow>
        <MaskedPhoneInput
          inputRef={phoneRef}
          setValue={setPhone}
          label={t('phone')}
          mask='{+1} (000) 000 0000'
        />

        <IonItem>
          <IonLabel className='form-label' position='floating'>
            {t('email')}
          </IonLabel>
          <IonInput
            tabIndex={2}
            autocomplete='off'
            value={email}
            type='email'
            required
            onIonChange={({ target: { value } }: any) =>
              setEmail(value)
            }></IonInput>
        </IonItem>

        {isFullForm && (
          <IonItem>
            <IonLabel className='form-label' position='floating'>
              {t('dateOfBirth')}
            </IonLabel>
            <IonDatetime
              displayFormat='MMMM DD YYYY'
              value={dateOfBirth}
              cancelText={t('cancel')}
              doneText={t('done')}
              // monthNames={language !== 'en' ? spanishMonthNames : undefined}
              onIonChange={({ target: { value } }: any) =>
                setDateOfBirth(value)
              }
            />
          </IonItem>
        )}
        <MaskedPhoneInput
          inputRef={zipRef}
          setValue={setZipCode}
          label={t('zipCode')}
          mask='00000'
        />

        <IonList lines='none'>
          <IonRow className='radio-button-row'>
            <IonItem mode='ios'>
              <IonLabel
                className='form-label ion-padding-bottom'
                position='floating'>
                {t('gender')}
              </IonLabel>
            </IonItem>
            <IonItem mode='md'>
              <IonRadioGroup
                value={sex}
                onIonChange={({ detail: { value } }) => setSex(value)}>
                <IonRow>
                  <IonItem>
                    <IonLabel className='form-radio-label'>
                      {t('male')}
                    </IonLabel>
                    <IonRadio slot='start' value='male' />
                  </IonItem>

                  <IonItem>
                    <IonLabel className='form-radio-label'>
                      {t('female')}
                    </IonLabel>
                    <IonRadio slot='start' value='female' />
                  </IonItem>

                  <IonItem>
                    <IonLabel className='form-radio-label'>
                      {t('other')}
                    </IonLabel>
                    <IonRadio slot='start' value='other' />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
            </IonItem>
          </IonRow>
          <IonRow className='radio-button-row'>
            <IonItem mode='ios'>
              <IonLabel
                className='form-label ion-padding-bottom'
                position='floating'>
                {t('preferredLanguage')}
              </IonLabel>
            </IonItem>
            <IonItem mode='md'>
              <IonRadioGroup
                value={language}
                onIonChange={({ detail: { value } }) => setLanguage(value)}>
                <IonRow>
                  <IonItem>
                    <IonRadio slot='start' value='en' />
                    <IonLabel className='form-radio-label'>English</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonRadio slot='start' value='es' />
                    <IonLabel className='form-radio-label'>Español</IonLabel>
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
            </IonItem>
          </IonRow>
        </IonList>

        <IonButton className='submit-button' onClick={submit}>
          {t(isSubmitting ? 'submitting' : 'submit')}
        </IonButton>
      </IonList>
    </IonContent>
  );
};

const MaskedPhoneInput = ({ inputRef, setValue, label, mask }: any) => {
  const [inputFocus, setInputFocus] = useState(false);

  const init = async () => {
    if (inputRef.current) {
      const maskOptions = {
        mask,
      };
      const iMask = IMask(inputRef.current, maskOptions);
      iMask.on('accept', () => setValue(iMask.unmaskedValue));
    }
  };

  useEffect(() => {
    init();
  }, [inputRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonItem className={inputFocus ? 'item-has-focus' : ''}>
      <IonLabel className='form-label' position='floating'>
        {label}
      </IonLabel>
      <IonInput
        name='maskedInput'
        className={`sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ${
          inputFocus ? 'has-value' : ''
        }`}>
        <input
          className='native-input sc-ion-input-md sc-ion-input-ios'
          required
          ref={inputRef}
          type='tel'
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(inputRef?.current.value ? true : false)}
          tabIndex={3}
        />
      </IonInput>
    </IonItem>
  );
};
export default React.memo(Register);
