enum ContactMethod {
  Chat = 'chat',
  Phone = 'phoneCall',
  Video = 'videoCall',
}

enum FormType {
  Full = 'full',
}

export { ContactMethod, FormType };
