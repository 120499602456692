import React, { createContext, useEffect, useState } from "react";
import { IonApp } from "@ionic/react";
import { initReactI18next } from "react-i18next";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import i18n from "i18next";

import Home from "./pages/Home";
import { IonReactRouter } from "@ionic/react-router";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Route, Switch } from "react-router";
import Register from "./pages/Register";
import Success from "./pages/Success";
import en from "./resources/en.json";
import es from "./resources/es.json";
import i18next from "i18next";

Amplify.configure(awsconfig);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

export const Context = createContext({
  context: {},
  setContext: (_: any) => {},
});

const App: React.FC = () => {
  const [context, setContext] = useState({});

  useEffect(() => {
    var url = new URL(document.location.href);

    const lang = url.searchParams.get("lang") || "en";
    localStorage.setItem("lang", lang);
    i18next.changeLanguage(lang);
  }, []);

  return (
    <IonApp>
      <Context.Provider value={{ context, setContext }}>
        <IonReactRouter>
          <Switch>
            <Route path="/register" component={Register} />
            <Route path="/success" component={Success} />
            <Route path="/" component={Home} />
          </Switch>
        </IonReactRouter>
      </Context.Provider>
    </IonApp>
  );
};

export default App;
