import React, { useState } from "react";
import i18next from "i18next";

const DEFAULT_LANG = "en";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || DEFAULT_LANG
  );

  const isDefaultLanguage = () => selectedLanguage === DEFAULT_LANG;

  const changeLanguage = () => {
    const newLang = isDefaultLanguage() ? "es" : DEFAULT_LANG;
    localStorage.setItem("lang", newLang);
    i18next.changeLanguage(newLang);
    setSelectedLanguage(newLang);
  };

  return (
    <div className="float-top small-text bold" onClick={changeLanguage}>
      {isDefaultLanguage() ? "Español" : "English"}
    </div>
  );
};

export default React.memo(LanguageSelector);
