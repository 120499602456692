import React from "react";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage } from "@ionic/react";
import success from "../resources/SuccessIllustration.png";
import "./Success.css";
const Success: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage className="page successPage">
      <div className="successHeader">{t("successHeader")}</div>

      <IonContent className="contentWrapper">
        <div className="successText">{t("successText")}</div>
        <img className="successImg" src={success} alt="success" />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Success);
