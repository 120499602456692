import { ContactMethod } from "./types";

export interface INameToValueMap {
  [key: string]: any;
}

export interface IKeyVal {
  [key: string]: string;
}
export interface AppointmentInfo {
  id: string;
  customerId: string;
  customerName: string;
  coachId: string;
  coachName: string;
  coachRole: string;
  coachExtraInfo: string;
  coachAvatar: string;
  coachSlots: Array<Date>;
  appointmentDate: Date;
  appointmentMethod: ContactMethod;
  appointmentDuration: number;
  appointmentChatId: string;
}

export const AppointmentInfoFromApi = (data: AppointmentInfo) => {
  const ret = {
    ...data,
    coachSlots: data.coachSlots.map((slot) => new Date(slot)),
  };
  ret.appointmentMethod = Object.values(ContactMethod).includes(
    data.appointmentMethod
  )
    ? data.appointmentMethod
    : ContactMethod.Video;
  if (data.appointmentDate)
    ret.appointmentDate = new Date(data.appointmentDate);
  return ret;
};
