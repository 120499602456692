import "./Info.css";
import { useTranslation } from "react-i18next";
import { IonLoading, IonPage } from "@ionic/react";
import React from "react";

const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonLoading
        isOpen={isLoading}
        message={t("pleaseWait")}
        duration={5000}
      />
    </IonPage>
  );
};

export default React.memo(Loader);
