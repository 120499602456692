/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0102c2f1-de44-43d2-91b9-6374fe265f1c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_X8CUBRN5i",
    "aws_user_pools_web_client_id": "mkop31pgag6c0qqc7n3tpubgc",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "lagunaEntities-master",
            "region": "us-east-1"
        },
        {
            "tableName": "LagunaPeriodicDynamo-master",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "laguna-secure-docs164442-master",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "LagunaRestApi",
            "endpoint": "https://n39yfetlt4.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "LagunaSecureRestApi",
            "endpoint": "https://dobq77f1c4.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
