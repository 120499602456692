import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import LogRocket from "logrocket";

import "./Home.css";
import ApiRequests from "../common/api/api";
import { INameToValueMap } from "../common/interfaces";
import { Context } from "../App";
import { useIsMounted } from "../common/hooks/useIsMounted";
import Loader from "./Loader";

const Intro = lazy(() => import("./Intro"));
const Schedule = lazy(() => import("./Schedule"));
const Slots = lazy(() => import("./Slots"));
const Summary = lazy(() => import("./Summary"));
const Error = lazy(() => import("./Error"));

const Routes: INameToValueMap = {
  intro: Intro,
  schedule: Schedule,
  slots: Slots,
  summary: Summary,
};

if (process.env.NODE_ENV === "production") {
  LogRocket.init("5qn0ho/laguna", {
    network: {
      requestSanitizer: (request) => {
        if (request.method === "POST") {
          // ignore the request response pair
          return null;
        }

        // otherwise log the request normally
        return request;
      },
    },
    dom: {
      inputSanitizer: true,
    },
  });
}

const getLocation = (location: string) => {
  const splitted = location.split("/");
  const id = splitted[1];
  const path = splitted[2];
  return { id, path };
};

const Home: React.FC = () => {
  const history = useHistory();
  const { setContext } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useIsMounted();
  const location = useLocation();

  const navigate = (id: string, path: string) => {
    if (path) history.push(`/${id}/${path}`);
    else history.push(`/${id}/schedule`);
  };

  const init = async () => {
    try {
      setIsLoading(true);
      var { id, path } = getLocation(location.pathname);
      const appointmentInfo = await ApiRequests.getAppointmentInfo(id);
      if (process.env.NODE_ENV === "production") {
        LogRocket.identify(appointmentInfo.customerId, {
          name: appointmentInfo.customerName,
          id,
        });
      }
      setContext({ appointmentInfo: { ...appointmentInfo, id } });
      if (appointmentInfo.appointmentDate) navigate(id, "summary");
      else navigate(id, path);
    } catch (e) {
      const statusCode = e?.response?.status;
      if (statusCode === 404) {
        console.log("unknown user");
        navigate(id || "-", "error?type=notFound");
      } else if (statusCode === 410) {
        console.log("user expired");
        navigate(id, "error?type=expired");
      } else {
        console.log("unknown error");
        navigate(id, "error?type=unknown");
      }
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      init();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) return <Loader isLoading={true} />;

  return (
    <IonPage>
      <Suspense fallback={<Loader isLoading={true} />}>
        <IonRouterOutlet>
          <Switch>
            {Object.keys(Routes).map((route) => (
              <Route
                key={route}
                path={`/:id/${route}`}
                component={Routes[route]}
              />
            ))}
            <Route path="*" component={Error} />
          </Switch>
        </IonRouterOutlet>
      </Suspense>
    </IonPage>
  );
};

export default React.memo(Home);
